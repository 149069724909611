import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

export default class IndexPage extends React.Component {
  render() {
    const { posts, title } = this.props

    return (
      <section className="section">
        <h1 className="title has-text-centered">{title}</h1>
        <div className="container">
          <div className="content" />
          {posts.map(({ node: post }) => (
            <div
              className="content"
              style={{ border: '1px solid #eaecee', padding: '1em 1em' }}
              key={post.id}
            >
              <div className="columns">
                <div className="column is-4">
                  <img src={post.featured_media.source_url} alt="eyecatch" style={{ height: "150px", width: "300px", "object-fit": "cover"}} />
                </div>
                <div className="column post-main">
                  <div>
                    <Link className="subtitle is-5" to={post.slug}>
                      {post.title}
                    </Link>
                  </div>  
                  <div>
                    {post.date}
                    {' '}
    - posted by
                    {' '}
                    <Link to={`/author/${post.author.slug}`}>
                      {post.author.name}
                    </Link>
                  </div>
                  <div>
                    <Link className="button is-small" to={post.slug}>
                      続きを読む →
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    featured_media {
      source_url
    }
    author {
      name
      slug
      avatar_urls {
        wordpress_48
      }
    }
    date(formatString: "YYYY/MM/DD")
    slug
  }
`
